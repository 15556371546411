.checkBox {
  display: flex;
  margin-top: 40px;
  padding-bottom: 35px;
  position: relative;
}
.checkBox label {
  color: #454545;
  cursor: pointer;
  font-family: var(--AcuminProRegular);
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.41;
  max-width: 360px;
  position: relative;
}
.checkBox label:before {
  -webkit-appearance: none;
  background-color: transparent;
  border: 3px solid #0a0a33;
  content: '';
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
  padding: 10px;
  position: relative;
  vertical-align: middle;
  height: fit-content;
}
.checkBox input:checked + label:after {
  border: 3px solid #0a0a33;
  border-width: 0 3px 3px 0;
  content: '';
  display: block;
  height: 12px;
  left: 10px;
  position: absolute;
  top: 6px;
  transform: rotate(39deg);
  width: 7px;
}
.checkBox input {
  cursor: pointer;
  display: none;
  height: auto;
  margin-bottom: 0;
  padding: 0;
  width: auto;
}
